import React from 'react'
import GridListTile from '@material-ui/core/GridListTile';
import GridList from '@material-ui/core/GridList';
import { Link } from "gatsby"

import './styles.css'; 

const GridListComponent = ({ productsData, windowWidth }) => {
  return (
    <GridList className='grid-list' cellHeight={windowWidth < 768 ? 200 : 300} cols={windowWidth < 768 ? 1 : 3}>
      {productsData.map(tile => {
        const { frontmatter, id } = tile.node; 
        return (
          <GridListTile className='productContainer' style={{padding: 0}} key={id} cols={windowWidth < 768 ? 1 : (frontmatter.cols || 1)}>
            <Link to={frontmatter.path}>
              <div className='grid-list-background ' style={{ backgroundImage: `url(${frontmatter.image})`}} />
              <div className='productImageTitle'>
                <h3>
                  {frontmatter.title}
                  <span className='grid-tile-underline'></span>
                </h3>
              </div>
            </Link>
          </GridListTile>
        )
      })}
    </GridList>
  )
}

export default GridListComponent; 